@import "../../../../assets/styles/vars";

.button-state(@backgroundColor: none, @borderColor: transparent, @textColor: #ffffff) {
  color: @textColor;
  background: @backgroundColor;
  border-color: @borderColor;
}

//// Круглая кнопка с одинаковым цветом фона и рамки. текст белый
.button(@color: none, @borderColor: none, @textColor: #FFFFFF, @hoverDarken: 10, @hoverBorderDarken: 10) {
  // Обычное состояние, Фокус
  &, &:focus {
    .button-state(@color, @borderColor, @textColor);
  }
  // Навели
  &:hover {
    .button-state(darken(@color, @hoverDarken), darken(@borderColor, @hoverBorderDarken), @textColor);
  }
  // Нажали
  &:active {
    .button-state(fade(@color, 75), fade(@borderColor, 75), @textColor);
  }
  // Заблокирована
  &[disabled], &[disabled]:hover, &[disabled]:active {
    .button-state(#c4c5d6, #c4c5d6, #FFFFFF);
    cursor: default;
  }
}

//// Круглая кнопка с белым фоном и рамкой
.whiteButton(@borderColor: #000, @textColor: #000, @iconColor: #000) {
  // Обычное состояние, Фокус
  &, &:focus {
    .button-state(#FFF, @borderColor, @textColor);
    .anticon {color: @iconColor;}
  }
  // Навели
  &:hover {
    .button-state(#FFF, darken(@borderColor, 10), darken(@textColor, 10));
    .anticon {color: darken(@iconColor, 5);}
  }
  // Нажали
  &:active {
    .button-state(#FFF, fade(@borderColor, 75), fade(@textColor, 75));
    .anticon {color: fade(@iconColor, 75);}
  }
  // Заблокирована
  &[disabled], &[disabled]:hover, &[disabled]:active {
    .button-state(#c4c5d6, #c4c5d6, #FFFFFF);
    cursor: default;
    .anticon {color: #FFFFFF;}
  }
}

.blockButton(@colorName, @color) {
  .rr-action-block-btn.rr-action-block-btn--@{colorName} .anticon {
    color: @color;
  }
  .rr-action-link-btn.rr-action-link-btn--@{colorName} .anticon {
    color: @color;
  }
}

.actionButton(@colorName, @color) {
  .ant-btn.rr-action-btn.rr-action-btn--@{colorName}, .rr-action-btn.rr-action-btn--@{colorName} .ant-btn {
    .button(@color, @color, #FFFFFF, 10);
  }
}

.actionWhiteButton(@colorName, @borderColor, @textColor, @iconColor) {
  .ant-btn.rr-action-btn.rr-action-btn--@{colorName}, .rr-action-btn.rr-action-btn--@{colorName} .ant-btn {
    .whiteButton(@borderColor, @textColor, @iconColor);
  }
}

//////////////////////////////

.ant-btn.rr-action-btn, .rr-action-btn .ant-btn {
  height: 40px;
  padding: 0 22px;
  font-size: 14px;
  border-radius: 20px;
  font-weight: 500;
  margin-right: 0;
}

@media (max-width: 1199px) {
  .ant-btn.rr-action-btn, .rr-action-btn .ant-btn {
    padding: 0;
    width: 40px;
  }

  .ant-btn.rr-action-btn .anticon + span, .rr-action-btn .ant-btn .anticon + span {
    display: none;
  }
}

.ant-btn.rr-action-btn .anticon, .rr-action-btn .ant-btn .anticon {
  font-size: 22px;
  vertical-align: -0.25em;
}

//////////////////////////////

.ant-btn.rr-action-block-btn {
  border-width: 0;
  border-radius: 0;
  text-align: left;
  padding: 0 20px;
  height: 45px;
  width: 100%;
  color: #575962;
  display: flex; //inline-flex;
  align-items: center;
  overflow: hidden;
}

.ant-btn.rr-action-block-btn:not([disabled]):hover {
  background-color: #f4f5f8;
  color: #525a95;
}

.ant-btn.rr-action-block-btn:not([disabled]):hover .anticon {
  opacity: .8;
}

.ant-btn.rr-action-block-btn[disabled] {
  opacity: .5;
  color: #575962;
  background-color: unset;
}

.ant-btn.rr-action-block-btn[disabled] .anticon {
  color: #575962 !important;
}

.ant-btn.rr-action-block-btn .anticon {
  line-height: 0;
  font-size: 21px;
}

.ant-btn.rr-action-block-btn > .anticon + span {
  margin-left: 22px;
}

.ant-btn.rr-action-block-btn[ant-click-animating-without-extra-node]:after {
  animation: none;
}

//////////////////////////////

.ant-btn.rr-action-link-btn {
  padding: 0;
  height: 21px;
  line-height: 21px;
}

.ant-btn.rr-action-link-btn .anticon {
  font-size: 21px;
}

.ant-btn.rr-action-link-btn:not([disabled]):hover .anticon {
opacity: .75;
}

.ant-btn.rr-action-link-btn[disabled] .anticon {
color: @rr-colors-grey-6;
}

//////////////////////////////

.actionButton(green, @rr-colors-green);
.blockButton(green, @rr-colors-green);

.ant-btn.rr-action-btn.rr-action-btn--white-grey-1, .rr-action-btn.rr-action-btn--white-grey-1 .ant-btn {
.button(#FFFFFF, @rr-colors-grey-1, @rr-colors-grey-1, 0, 100);
border-style: dashed;
}

.blockButton(white-grey-1, @rr-colors-grey-1);

.actionButton(grey-2, @rr-colors-grey-2);
.blockButton(grey-2, @rr-colors-grey-2);

.actionButton(grey-3, @rr-colors-grey-3);
.blockButton(grey-3, @rr-colors-grey-3);

.actionButton(purple-1, @rr-colors-purple-1);
.blockButton(purple-1, @rr-colors-purple-1);

.actionButton(purple-2, @rr-colors-purple-2);
.blockButton(purple-2, @rr-colors-purple-2);

.actionButton(purple-3, @rr-colors-purple-3);
.blockButton(purple-3, @rr-colors-purple-3);

.actionButton(purple-4, @rr-colors-purple-4);
.blockButton(purple-4, @rr-colors-purple-4);

.actionButton(azure, @rr-colors-azure);
.blockButton(azure, @rr-colors-azure);

.actionButton(orange-1, @rr-colors-orange-1);
.blockButton(orange-1, @rr-colors-orange-1);

.actionButton(red-1, @rr-colors-red-1);
.blockButton(red-1, @rr-colors-red-1);

.actionButton(cyan, @rr-colors-cyan);
.blockButton(cyan, @rr-colors-cyan);

.actionWhiteButton(white-orange-1-solid, @rr-colors-orange-1, @rr-colors-grey-1, @rr-colors-orange-1);
.blockButton(white-orange-1-solid, @rr-colors-orange-1);

.actionWhiteButton(white-grey-2-solid, @rr-colors-grey-2, @rr-colors-grey-1, @rr-colors-grey-2);
.blockButton(white-grey-2-solid, @rr-colors-grey-2);

.actionButton(blue-1, @rr-colors-blue-1);
.blockButton(blue-1, @rr-colors-blue-1);

.actionButton(blue-2, @rr-colors-blue-2);
.blockButton(blue-2, @rr-colors-blue-2);

.actionButton(brown, @rr-colors-brown);
.blockButton(brown, @rr-colors-brown);

.actionButton(pink-1, @rr-colors-pink-1);
.blockButton(pink-1, @rr-colors-pink-1);

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';