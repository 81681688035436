@import "./../../../../assets/styles/vars";

.rr-range-picker {
  position: relative;
  min-width: 320px;
  width: unset;
}

.rr-range-picker.ant-input {
  padding: 0;
  //font-size: 16px;
}

.rr-range-picker.rr-input-error {
  background-color: rgba(244, 82, 109, 0.4);
}

.rr-range-picker.rr-range-picker-disabled {
  background-color: #fbfbfb;
}

.rr-range-picker:not(.rr-range-picker-disabled) {
  cursor: text;
}

.rr-range-picker > div {
  display: flex;
}

.rr-range-picker > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rr-range-picker-input {
  width: 130px;
  height: 36px;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;

  &::placeholder {
    font-weight: @rr-default-input-placeholder-font-weight;
    color: @rr-default-input-placeholder-color;
  }
}

.rr-range-picker-disabled .rr-range-picker-input {
  opacity: 0.3;
}

.rr-range-picker-separator {
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline-block;
  user-select: none;
  font-size: 24px;
  margin-top: -5px;
  color: rgba(0, 0, 0, .4);
}

.rr-range-picker-disabled .rr-range-picker-separator {
  opacity: 0.5;
}

.rr-range-picker-calendar-icon, .rr-range-picker-clear-icon {
  color: #383e75;
}

.rr-range-picker-disabled .rr-range-picker-icons {
  opacity: 0.5;
  cursor: default;
}

.rr-range-picker-icons {
  display: block;
  width: 14px;
  height: 14px;
  user-select: none;
  cursor: pointer;
  margin-right: 12px;
}

.rr-range-picker.rr-range-picker-disabled {
  cursor: default;
}

.rr-range-picker-calendar-icon, .rr-range-picker-clear-icon {
  position: absolute;
  transition: all 0.3s;
  font-size: 14px;
}

.rr-range-picker.rr-range-picker-allow-clear:not(.rr-range-picker-disabled).rr-range-picker-range-selected .rr-range-picker-icons:hover .rr-range-picker-calendar-icon {
  opacity: 0;
}

.rr-range-picker .rr-range-picker-clear-icon {
  opacity: 0;
  display: none;
}

.rr-range-picker.rr-range-picker-allow-clear:not(.rr-range-picker-disabled).rr-range-picker-range-selected .rr-range-picker-icons:hover .rr-range-picker-clear-icon {
  opacity: 1;
}

.rr-range-picker.rr-range-picker-allow-clear.rr-range-picker-range-selected .rr-range-picker-clear-icon {
  display: block;
}

.rr-range-picker:not(.rr-range-picker-disabled).rr-range-picker-range-selected .rr-range-picker-input:hover
{
  font-weight: 700;
  color: #3ea9f5;
}

.rr-range-picker-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.rr-range-picker-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

// .rr-range-picker-input::placeholder
.rr-range-picker:not(.rr-range-picker-disabled) .rr-range-picker-bg:hover + div .rr-range-picker-input,
.rr-range-picker:not(.rr-range-picker-disabled).rr-range-picker-range-selected .rr-range-picker-bg:hover + div .rr-range-picker-separator,
.rr-range-picker:not(.rr-range-picker-disabled).rr-range-picker-hover .rr-range-picker-input,
.rr-range-picker:not(.rr-range-picker-disabled).rr-range-picker-hover.rr-range-picker-range-selected .rr-range-picker-separator

//.rr-range-picker:not(.rr-range-picker-disabled) .rr-range-picker-bg:hover + div .rr-range-picker-input::placeholder,
//.rr-range-picker:not(.rr-range-picker-disabled) .rr-range-picker-bg:hover + div .rr-range-picker-separator,
//.rr-range-picker:not(.rr-range-picker-disabled).rr-range-picker-hover .rr-range-picker-input::placeholder
//.rr-range-picker:not(.rr-range-picker-disabled).rr-range-picker-hover .rr-range-picker-separator,
{
  color: #3ea9f5;
}

.rr-range-picker:not(.rr-range-picker-disabled) .rr-range-picker-bg:hover + div .rr-range-picker-input,
.rr-range-picker:not(.rr-range-picker-disabled).rr-range-picker-hover .rr-range-picker-input{
  font-weight: 700 !important;
}

.rr-range-calendar .ant-calendar-my-select {
  line-height: 62px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';