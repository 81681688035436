.rr-link-btn-2.ant-btn {
  height: unset;
  line-height: unset;
  padding: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.rr-link-btn-2 > .anticon {
  line-height: 0;
  margin-right: 14px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';