.ReactTable {
  border-width: 0;
  color: #575962;
}

.ReactTable .rt-table {
  overflow: no-display;
}

.ReactTable .rt-noData {
  display: none;
}

// Заголовок /////////////////////
//////////////////////////////////
.ReactTable .rt-thead {
  max-height: 55px;
  height: 55px;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  background-color: #f4f5f8;
}

.ReactTable .rt-thead .rt-th {
  border-right-width: 0;
  max-height: 55px;
  padding: 0;
  font-weight: 600;
  //line-height: 1.5;
  overflow: unset;
  text-align: center;
  white-space: unset;
}

.ReactTable .rt-thead .rt-th .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 10px;
  top: 0;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  //z-index: 1;
}

.ReactTable div.rt-tr {
  align-items: center;
  height: 55px;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}

.ReactTable .rt-th.-cursor-pointer {
  color: #383f77;
}

.ReactTable .rt-th.-cursor-pointer:hover {
  color: fade(#383f77, 85%);
}


.ReactTable .rt-thead .rt-th > div:first-child {
  padding: 0;
  height: 55px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content:center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactTable .rt-thead .rt-th > div > div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactTable .rt-thead .rr-grid-table-header-cell {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
}

.ReactTable .rt-thead .rr-grid-table-header-cell > div:nth-child(1) {
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 52px;
  //display: block;
}

//.ReactTable .rt-thead .rr-grid-table-header-cell > *:nth-child(1) > div {
  //overflow: hidden;
  //text-overflow: ellipsis;
//}

//.ReactTable .rt-thead .rr-grid-table-header-cell > *:nth-child(2) {
  //flex-grow: 2;
//}

//.ReactTable .rt-thead .rr-grid-table-header-cell > *:nth-child(3) {
  //flex-grow: 2;
//}

.ReactTable .rt-thead .rt-th .anticon.rr-grid-table-remove-icon {
  opacity: 0;
}

.ReactTable .rt-thead .rt-th:hover .anticon.rr-grid-table-remove-icon {
  opacity: 1;
}

// Тело ////////////////////////////////////////////////////

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom-width: 0;
}

.ReactTable .rt-tbody .rt-tr-group:nth-child(2n)  {
  background-color: #f8f9fa;
}


.ReactTable .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #ebedf2;
}

.ReactTable .rt-tbody .rt-tr.-odd {
  background: #fff;
}

.ReactTable .rt-tbody .rt-tr.-even {
  background: #f8f9fa;
}

.ReactTable .rt-tbody .rt-td {
  padding: 0;
  border-right-width: 0;
  //max-height: 55px;
  text-align: center;
  white-space: unset;
}

.ReactTable .rt-tbody .rt-td > div {
  padding: 0 6px;
  height: 55px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content:center;
  //text-overflow: ellipsis;
  //overflow: hidden;
}

.ReactTable .rt-tbody .rt-td > div > div {
  //text-overflow: ellipsis;
  //overflow: hidden;
  max-height: 40px;
  width: 100%;
}

.ReactTable .rt-tbody .rt-td .rr-grid-shortName-cell, .ReactTable .rt-tbody .rt-td .rr-grid-text-cell-center, .ReactTable .rt-tbody .rt-td .rr-grid-text-cell-left {
  text-overflow: ellipsis;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow-wrap: break-word;
}

.ReactTable .rt-tbody .rt-td .rr-grid-text-cell-left {
  text-align: left;
  padding-left: 10px;
}

///// Разработка перетаскивания ячеек
.draggable-header {
  padding: 0;
  height: 55px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rt-dragged-item {
  background-color: #ddd;
  border: 1px solid #ccc;
  font-weight: 700;
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 0;
  height: 55px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rt-dragged-item > div {
  background-color: #00FF00;
  overflow: hidden;
  text-overflow: ellipsis;
  //display: none;


}

.rt-dragged-item .rr-grid-table-header-cell {
  padding: 0;
  height: 55px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;

  margin: 0;
  padding: 0;
  //display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #00afee;
}

//

.rt-dragged-item .rr-grid-sort-icon {
  display: none;
}
/*
.rt-draggable-container .ReactTable .rt-thead .rt-th {
  outline: none;
}

.rt-draggable-container div.draggable-header.enable-drag {
  padding: 2px 8px;
}

.rt-draggable-container div[draggable=true].draggable-header.enable-drag {
  display: inline-flex;
}

.rt-draggable-container .draggable-header.enable-drag:hover {
  cursor: move;
}

.rt-draggable-container div.transparent-border, .rt-draggable-container div[draggable=true] {
  border: 1px solid transparent;
}

.rt-draggable-container div.transparent-border.rt-drag-enter-item, .rt-draggable-container div[draggable=true].rt-drag-enter-item {
  border: 1px dashed #777;
}

.rt-draggable-container .arrow {
  display: inline-block;
  height: 8px;
  position: relative;
  width: 8px;
}

.rt-draggable-container .arrow:after {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
  content: "";
  display: inline-block;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}

.rt-draggable-container .arrow.arrow-bar:before {
  bottom: 0;
  content: "";
  height: 17px;
  position: absolute;
  right: -1px;
  transform-origin: bottom;
  width: 2px;
  transform: rotate(-45deg);
}

.rt-draggable-container .arrow.is-top {
  transform: rotate(225deg);
}

.rt-draggable-container .arrow.is-right {
  transform: rotate(315deg);
}

.rt-draggable-container .arrow.is-bottom {
  transform: rotate(45deg);
}

.rt-draggable-container .arrow.is-left {
  transform: rotate(135deg);
}

.rt-draggable-container .arrow .arrow:after, .rt-draggable-container .arrow:after {
  border-color: #000;
}

.rt-draggable-container .arrow .arrow:before, .rt-draggable-container .arrow:before {
  background-color: #000;
}
*/
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';