.rr-product-variant-select {

}

.rr-product-variant-select .ant-select-selection__placeholder, .rr-product-variant-select .ant-select-search__field__placeholder {
  margin-top: -6px;
}

.rr-product-variant-select .ant-select-selection--multiple {
  padding-bottom: 7px;
  max-height: 157px;
  overflow-y: auto;
}

.rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 8px;
}

.rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__choice {
  padding: 0;

  border-radius: unset;
  color: unset;
  border: unset;
  background-color: unset;
  font-weight: unset;
}

.rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__choice .rr-product-variant-select__choice {
  border: 1px solid #21bfa4;
  color: #21bfa4;
  padding: 0px 10px;
  padding-right: 30px;
  border-radius: 4px;
  height: 32px;
  position: relative;
  font-weight: 600;
}

.rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__choice .rr-product-variant-select__choice-warning {
  border: 1px solid #ff8e43;
  background-color: #ff8e43;
  color: #ffffff;
}

.rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__choice .rr-product-variant-select__choice-error {
  border: 1px solid #ff4d51;
  background-color: #ff4d51;
  color: #ffffff;
}

.rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__choice .rr-product-variant-select__choice-not-available {
  //border: 1px solid #ff4d51;
  //color: #ff4d51;
}

.rr-product-variant-select .ant-select-selection--multiple > ul > li, .rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 32px;
  line-height: 30px;
  margin-right: 10px;
}

.rr-product-variant-select .ant-select-selection__choice__remove {
  opacity: 0;
}

.rr-product-variant-select .ant-select-arrow {
  margin-top: 0px;
}

.rr-product-variant-select__choice-closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
}

.rr-product-variant-select-menu-item {
  display: flex;
  padding-left: 50px;
  align-items: center;
  line-height: 0;
}

.rr-product-variant-select-menu-item > div {
  padding: 8px 2px;
  text-align: center;
}

.rr-product-variant-select-menu-item > div.rr-product-variant-select-menu-item__problem {
  padding-left: 0;
}

.rr-product-variant-select-menu-item > div.rr-product-variant-select-menu-item__title {
  flex-grow: 1;
  text-align: left;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rr-product-variant-select-menu-item > div.rr-product-variant-select-menu-item__calendar {
  max-width: 90px;
  min-width: 90px;
}

//.rr-product-variant-select-menu-item-not-available {
//background-color: #ffc6c7;
//color: #00FF00;
//}

.rr-product-variant-select-menu-item__warning {
  background-color: #ffecc4;
}

.rr-product-variant-select-menu-item__error {
  background-color: #ffd7da; //#f4516c;
}

.rr-product-variant-select-menu-item-not-available__product .rr-grid-shortName-cell,
.rr-grid-problem-row.rr-product-variant-select-menu-item-not-available__product .anticon {
  color: #abacb0 !important;
}

.rr-grid-problem-row.rr-product-variant-select-menu-item-not-available__product .anticon.rr-grid-table-calendar-icon {
  color: #525891;
}

.rr-grid-problem-row.rr-product-variant-select-menu-item-not-available__product .anticon.rr-grid-table-calendar-icon:hover {
  color: fade(#525891, 75%);
}

/*
.rr-product-variant-select-menu-item-not-available__element {
    //background-color: #f4516c;
}

.rr-product-variant-select-menu-item-not-available__element .rr-grid-shortName-cell,
.rr-grid-problem-row.rr-product-variant-select-menu-item-not-available__element .anticon,
.rr-product-variant-select-menu-item-not-available__element .anticon.rr-grid-table-calendar-icon
{
    //color: #ffffff;
}
*/

.rr-product-variant-select-menu-item__warning .rr-grid-shortName-cell,
.rr-grid-problem-row.rr-product-variant-select-menu-item__warning .anticon,
.rr-product-variant-select-menu-item__warning .anticon.rr-grid-table-calendar-icon,
.rr-grid-problem-row.rr-product-variant-select-menu-item__warning.rr-product-variant-select-menu-item-not-available__product .anticon.rr-grid-table-calendar-icon,
.rr-product-variant-select-menu-item__error .rr-grid-shortName-cell,
.rr-grid-problem-row.rr-product-variant-select-menu-item__error .anticon,
.rr-product-variant-select-menu-item__error .anticon.rr-grid-table-calendar-icon,
.rr-grid-problem-row.rr-product-variant-select-menu-item__error.rr-product-variant-select-menu-item-not-available__product .anticon.rr-grid-table-calendar-icon {
  //color: #ffffff;
}

.rr-product-variant-select-dropdown .ant-select-dropdown-menu-item {
  padding: 0;
}

.rr-product-variant-select-dropdown .ant-select-dropdown-menu-item:nth-child(2n) {
  background-color: #f8f9fa;
}

.rr-product-variant-select-dropdown.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon, .rr-product-variant-select-dropdown.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: block;
  color: #21bfa4;
  left: 14px;
  right: unset;
  font-size: 20px;
}

.rr-product-variant-select-dropdown.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 0;
}

.rr-product-variant-select-dropdown.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-left: 12px;
}

.rr-product-variant-select-dropdown .ant-select-dropdown-menu-item-selected, .rr-product-variant-select-dropdown .ant-select-dropdown-menu-item-selected:hover {
  background-color: unset;
  color: unset;
}

.rr-product-variant-select-dropdown .ant-select-dropdown-menu-item-selected:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: unset;
  color: unset;
}

.rr-product-variant-select-dropdown .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: unset;
  color: unset;
}

.rr-product-variant-select-dropdown .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled).ant-select-dropdown-menu-item-selected {
  background-color: unset;
  color: unset;
}

.rr-product-variant-select-dropdown .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #ebedf2;
}

.rr-product-variant-select-dropdown .rr-product-variant-select-menu-item__title span:hover {
  text-decoration: underline;
}

.rr-product-variant-select .ant-select-selection--multiple .ant-select-selection__choice .rr-product-variant-select__choice > span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rr-product-variant-select-dropdown .rr-product-variant-select-menu-item {
  min-height: 36px;
}
@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';