.rr-rich-text-editor .ql-toolbar.ql-snow {
    border: 1px solid #dcdfe8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.rr-rich-text-editor .ql-container.ql-snow {
    border-color: #dcdfe8;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.rr-rich-text-editor .ql-editor {
    min-height: 100px;
    cursor: text;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 14px;
    color: rgba(87, 89, 98, 0.8);
}

.rr-rich-text-editor .ql-snow .ql-tooltip {
    z-index: 1;
}

.rr-rich-text-editor .ql-snow .ql-picker {
    line-height: 1;
}

.rr-rich-text-editor .ql-snow a, .rr-rich-text-editor .ql-snow a:hover {
    color: #06c !important;
    text-decoration: underline;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';