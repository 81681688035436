.rr-simpleOrderPage-actions {

}

.rr-simpleOrderPage-bottomBlock {
  border-top: 1px solid #eaeaea;

  .rr-simpleOrderPage-bottomBlock-contract {
    @media (min-width: 1600px) {
      border-right: 1px solid #eaeaea;
    }
  }

  .rr-simpleOrderPage-bottomBlock-tax {
    @media (min-width: 992px) {
      border-right: 1px solid #eaeaea;
    }
  }

  .rr-simpleOrderPage-bottomBlock-price {
    @media (max-width: 991px) {
      border-top: 1px solid #eaeaea;
    }
  }
}

.rr-simpleOrderPage-descriptionBlock {

  margin-top: 32px;

  .ant-card-body {
    padding: 0;
  }

  .rr-simpleOrderPage-descriptionBlock-description {
    padding: 27px 32px 32px;
    @media (min-width: 992px) {
      border-right: 1px solid #eaeaea;
    }
  }

  .rr-simpleOrderPage-descriptionBlock-comment {
    padding: 27px 32px 32px;
  }
}

.rr-simpleOrderPage-bottomBlock-contract > div > .ant-row > .ant-col {
  @media (min-width: 1600px) {
    border: unset !important;
  }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';