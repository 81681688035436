.rr-money-input-inline.ant-input-number {
  min-width: unset;
  background-color: transparent;
  width: unset;
  display: inline-block;
}

.rr-money-input-inline .ant-input-number-handler {
  display: none;
}

.rr-money-input-inline .ant-input-number-input-wrap {
  margin: 0 0px;
}

.rr-money-input-inline.ant-input-number .ant-input-number-input {
  border-radius: 4px;
  border-left-width: 1px;
  border-right-width: 1px;
  text-align: left;
  padding: 0 5px;
}

.rr-money-input-inline-white.ant-input-number .ant-input-number-input {
  color: #ffffff;
}

.rr-money-input-inline-white.ant-input-number .ant-input-number-input:focus {
  border: 1px solid #ffffff;
}

.rr-inline-editable {
  //border-bottom: 1px dotted transparent;
  cursor: text;
  text-decoration: underline;
}

.rr-inline-editable:hover {
  opacity: .75;
  //border-bottom: 1px dotted #9ea2c7;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';