@import 'lib';
@import 'vars';
@import './base';
@import './other';
@import './components/calendar';
@import './components/slider';
@import './components/button';
@import './components/checkbox';
@import './components/radio';
@import './components/switch';
@import './components/tooltip';
@import './components/badge';
@import './components/select';
@import './components/detailed-select.less';
@import './components/input';
@import './components/table';
@import './components/pagination';
@import './components/card';
@import './components/form';
@import './components/popover';
@import './components/inputNumber';
@import './components/modal';
@import './components/tree';
@import './components/upload';
@import './components/alert';
@import './components/spin';
@import './components/tabs';
@import './components/select-tree';
@import './components/avatar';
@import './components/notification';
@import './components/skeleton';
@import './components/timePicker';
@import './components/multiple-select';
@import './components/collapse';
@import './components/progress';

html {
    overflow-x: hidden;
}
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    font-feature-settings: normal;
    font-variant: normal;
    width: 100vw;
}

.rr-basicLayout .ant-layout {
    transition: all 0.2s;
}

a {
    color: #6f727d;
}

a:hover {
    color: #6f727d;
}

//////////////// Хаки
// При быстрых кликах на лэйбл происходит выбор текста
.ant-checkbox-wrapper,
.ant-radio-button-wrapper {
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

//
.ant-radio-button-wrapper:focus-within,
.ant-radio-button-wrapper-checked:focus-within,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: none;
}

* {
    outline: none;
}

h5,
.h5 {
    font-size: 18px;
    font-weight: bold;
    color: #575962;
    word-break: break-word;
    min-height: 27px;
}

a.rr-danger {
    color: #f4516c;
    text-decoration: underline;
}

.rr-nounderline a:not(:hover) {
    text-decoration: none !important;
}

.rr-value.rr-danger {
    color: #f4516c;
}

.rr-value.rr-danger a {
    color: #f4516c;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
}

.rr-value.rr-danger a:hover {
    color: fade(#f4516c, 70);
}

.rr-value-hint {
    color: #ccc;
    font-size: 12px;
    font-weight: 400;
}

.rr-danger span + span {
    color: #f4516c;
    font-size: 14px;
    font-weight: 400;
}

.rr-form-item {
    word-break: break-word;
}

.rr-form-item:not(:last-child) {
    margin-bottom: 18px;
}

.rr-form-item .rr-label {
    margin-bottom: 6px;
}

.rr-tabs {
    .ant-tabs-extra-content {
        display: flex;
        align-items: center;
    }
}

.rr-tabs .ant-tabs-extra-content > *:not(:last-child) {
    margin-right: 16px;
}

.rr-tabs .ant-tabs-extra-content button.ant-btn-round {
    transition: all 0s;
}

@media (max-width: 1199px) {
    .rr-tabs .ant-tabs-extra-content button.ant-btn-round {
        padding: 0px 10px;
        width: 40px;
    }

    .rr-tabs .ant-tabs-extra-content button span {
        display: none;
    }

    .ant-card .ant-card-extra button.ant-btn-round {
        padding: 0px 10px;
        width: 40px;
    }

    .ant-card .ant-card-extra button span {
        display: none;
    }
}

// Список категорий, в строчку
.rr-entity-page-categories {
    font-size: 14px;
    font-weight: 400;
    color: #383f77;
}

.rr-empty-value {
    font-size: 18px;
    font-weight: 400;
    color: #aaaaaa;
}

.rr-0-value {
    font-size: 18px;
    font-weight: 400 !important;
    color: #aaaaaa !important;
}

.rr-label {
    color: rgba(87, 89, 98, 0.9);
    margin-bottom: 5px;
}

.rr-text {
    font-weight: 500;
    word-break: break-word;
    white-space: pre-wrap;
}

.rr-click-link {
    user-select: none !important;
    color: #343f9a !important;
    text-decoration: underline !important;
    margin-left: 4px;
    transition: opacity 0.3s;
    animation-timing-function: ease-out;

    &:hover {
        color: #343f9a !important;
        text-decoration: underline !important;
    }
}

.rr-link {
    color: @rr-link-default-color;
    text-decoration: none;
}

.rr-link-underline {
    color: @rr-link-default-color;
    text-decoration: underline;
}

.rr-link:hover {
    color: @rr-link-hover-color;
    text-decoration: underline;
}

.rr-link2 {
    color: #525993;
    text-decoration: none;
}

.rr-link2:hover {
    color: fade(#525993, 85%);
    text-decoration: underline;
}

.rr-value {
    font-size: 18px;
    font-weight: bold;
    color: #575962;
    word-break: break-word;
    min-height: 27px;
}

.rr-value-signature {
    font-size: 11px;
}

.rr-value-date-time {
    font-size: 18px;
    font-weight: 500;
}

.rr-value.rr-danger {
    color: #f4516c;
}

.rr-value-phone .anticon,
.rr-value-email .anticon {
    opacity: 0;
    font-size: 24px;
    vertical-align: top;
}

.rr-value-phone > span,
.rr-value-email > span {
    margin-right: 14px;
}

.rr-value-phone:hover .anticon,
.rr-value-email:hover .anticon {
    opacity: 1;
}

.rr-is-touch-device .rr-value-phone .anticon,
.rr-is-touch-device .rr-value-email .anticon {
    opacity: 1;
}

.rr-value a {
    color: #525993;
}

.rr-value a:hover {
    text-decoration: underline;
    color: fade(#525993, @rr-link-hover-color-opacity-percents);
}

@media (min-width: 1600px) {
    .rr-page-renter-block-with-right-border {
        border-right: 1px solid #eaeaea;
    }
}

@media (min-width: 1600px) {
    .rr-page-renter-block-with-left-border {
        border-left: 1px solid #eaeaea;
    }
}

@media (max-width: 1600px) {
    .rr-page-renter-block-with-top-border {
        border-top: 1px solid #eaeaea;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .rr-page-renter-block-with-top-border-lg {
        border-top: 1px solid #eaeaea;
    }
}

.ant-card.rr-card-without-padding .ant-card-body {
    padding: 0;
}

///
.rr-status-bg-instance-NEW {
    background-color: #04b0f1;
}

.rr-status-bg-instance-ACTIVE {
    background-color: #34bfa3;
}

.rr-status-bg-instance-TEMPORARY_WITHDRAWN {
    background-color: #f25572;
}

.rr-status-bg-instance-UNDER_MAINTENANCE {
    background-color: #525993;
}

.rr-status-bg-instance-UNDER_MAINTENANCE_BORDERED {
    background-color: #525993;
    border: 1px solid #fff;
}

.rr-status-bg-instance-DECOMMISSIONED {
    background-color: #a0a0a0;
}

.rr-status-bg-instance-SUPPLIER_ACTIVE {
    background-color: #fff;
    border: 2px solid #ff8d3a;
    color: #575962;
}

.rr-status-bg-instance-SUPPLIER_DECOMMISSIONED {
    background-color: #fff;
    border: 3px solid #a0a0a0;
    color: #575962;
}
///

.rr-status-bg-IN_PROGRESS {
    background-color: @rr-status-inProgress-color;
}

.rr-status-bg-ACTIVE {
    background-color: @rr-status-active-color;
}

.rr-status-bg-NEW {
    background-color: @rr-status-new-color;
}

.rr-status-bg-PLANED {
    background-color: @rr-status-planned-color;
}

.rr-status-bg-BLOCKED {
    background-color: @rr-status-blocked-color;
}

.rr-status-bg-NOT_AVAILABLE {
    background-color: @rr-status-notAvailable-color;
}

.rr-status-bg-PLANNED {
    background-color: @rr-status-planned-color;
}

.rr-status-bg-FINISHED {
    background-color: @rr-status-Finished-color;
}

.rr-status-bg-INVITED {
    background-color: @rr-operation-status-rent-color;
}

//// ------------------
.rr-status-bg-ORDERED {
    background-color: @rr-operation-status-order-color;
}

.rr-status-bg-BOOKED {
    background-color: @rr-operation-status-book-color;
}

.rr-status-bg-RENT,
.rr-status-bg-RENTED {
    background-color: @rr-operation-status-rent-color;
}

.rr-status-bg-RETURNED {
    background-color: @rr-operation-status-return-color;
}

.rr-status-bg-RETURNED_BROKEN {
    background-color: @rr-operation-status-returnbroken-color;
}

.rr-status-bg-LOST_DURING_RENT {
    background-color: @rr-operation-status-lostnoreturn-color;
}

.rr-status-bg-CANCELED {
    background-color: @rr-operation-status-cancel-color;
}

.rr-status-bg-UNDER_MAINTENANCE {
    background-color: #08004a;
}

.rr-status-bg-DECOMMISSIONED {
    background-color: @rr-operation-status-cancel-color;
}

.rr-status-bg-OTHER,
.rr-status-bg-MIXED {
    background-color: rgb(82, 89, 147);
}

.rr-status-bg-DRAFT {
    background-color: #fff;
    border: 1px dashed #575962 !important;
    color: #575962 !important;
}

.rr-status-bg-SUBRENT,
.rr-status-bg-instance-SUBRENT {
    // TODO херня какая-то, Паша
    background-color: #fff;
    //color: @rr-operation-status-rent-color;
    color: #575962;
    border: 2px solid @rr-operation-status-rent-color;
}

.rr-status-bg-SUBRENT_CANCELED {
    background-color: #fff;
    //color: @rr-operation-status-cancel-color;
    color: #575962;
    border: 2px solid @rr-operation-status-cancel-color;
}

.rr-status-bg-SUBRENT_DRAFT {
    background-color: #fff;
    //color: #575962;
    color: #575962;
    border: 1px dashed #575962;
}

.rr-status-bg-SUBRENT_SHIPMENT_BOOKED {
    background-color: #fff;
    //color: @rr-operation-status-book-color;
    color: #575962;
    border: 2px solid @rr-operation-status-book-color;
}

.rr-status-bg-SUBRENT_RETURNED_TO_SHIPPER {
    background-color: #fff;
    //color: @rr-operation-status-return-color;
    color: #575962;
    border: 2px solid @rr-operation-status-return-color;
}

.rr-status-bg-payment-PLANNED {
    background-color: @rr-operation-status-order-color;
}

.rr-status-bg-payment-DONE {
    background-color: @rr-operation-status-return-color;
}

.rr-status-bg-payment-DELETED {
    background-color: @rr-operation-status-cancel-color;
}

.rr-status-bg-COORDINATION {
    background-color: @rr-operation-status-order-color;
}

/////////////////////////////////////////////////

.rr-status-border-IN_PROGRESS {
    border: 2px solid @rr-status-inProgress-color;
}

.rr-status-border-ACTIVE {
    border: 2px solid @rr-status-active-color;
}

.rr-status-border-NEW {
    border: 2px solid @rr-status-new-color;
}

.rr-status-border-BLOCKED {
    border: 2px solid @rr-status-blocked-color;
}

.rr-status-border-NOT_AVAILABLE {
    border: 2px solid @rr-status-notAvailable-color;
}

.rr-status-border-FINISHED {
    border: 2px solid @rr-status-Finished-color;
}

.rr-status-border-INVITED {
    border: 2px solid @rr-operation-status-rent-color;
}

//// ------------------
.rr-status-border-ORDERED {
    border: 2px solid @rr-operation-status-order-color;
}

.rr-status-border-BOOKED {
    border: 2px solid @rr-operation-status-book-color;
}

.rr-status-border-RENT {
    border: 2px solid @rr-operation-status-rent-color;
}

.rr-status-border-RETURNED {
    border: 2px solid @rr-operation-status-return-color;
}

.rr-status-border-RETURNED_BROKEN {
    border: 2px solid @rr-operation-status-returnbroken-color;
}

.rr-status-border-LOST_DURING_RENT {
    border: 2px solid @rr-operation-status-lostnoreturn-color;
}

.rr-status-border-CANCELED {
    border: 2px solid @rr-operation-status-cancel-color;
}

.rr-status-border-UNDER_MAINTENANCE {
    border: 2px solid #08004a;
}

.rr-status-border-DECOMMISSIONED {
    border: 2px solid @rr-operation-status-cancel-color;
}

///////////////////////

.rr-status-color-IN_PROGRESS {
    color: @rr-status-inProgress-color;
}

.rr-status-color-ACTIVE {
    color: @rr-status-active-color;
}

.rr-status-color-NEW {
    color: @rr-status-new-color;
}

.rr-status-color-BLOCKED {
    color: @rr-status-blocked-color;
}

.rr-status-color-NOT_AVAILABLE {
    color: @rr-status-notAvailable-color;
}

.rr-status-color-FINISHED {
    color: @rr-status-Finished-color;
}

.rr-status-color-INVITED {
    color: @rr-operation-status-rent-color;
}

//// ------------------
.rr-status-color-ORDERED {
    color: @rr-operation-status-order-color;
}

.rr-status-color-BOOKED {
    color: @rr-operation-status-book-color;
}

.rr-status-color-RENT {
    color: @rr-operation-status-rent-color;
}

.rr-status-color-RETURNED {
    color: @rr-operation-status-return-color;
}

.rr-status-color-RETURNED_BROKEN {
    color: @rr-operation-status-returnbroken-color;
}

.rr-status-color-LOST_DURING_RENT {
    color: @rr-operation-status-lostnoreturn-color;
}

.rr-status-color-CANCELED {
    color: @rr-operation-status-cancel-color;
}

.rr-status-color-UNDER_MAINTENANCE {
    color: #08004a;
}

.rr-status-color-DECOMMISSIONED {
    color: @rr-operation-status-cancel-color;
}

// BOOK Бронь
.rr-operation-status-color-EDIT {
    color: #5c63a3;
}

// BOOK Бронь
.rr-operation-status-color-BOOK {
    color: @rr-operation-status-book-color;
}

// CANCEL Отмена
.rr-operation-status-color-CANCEL {
    color: @rr-operation-status-cancel-color;
}

// CORRECT Корректировка
.rr-operation-status-color-CORRECT {
    color: @rr-operation-status-correct-color;
}

// LOSTNORETURN Списание
.rr-operation-status-color-LOST_NO_RETURN {
    color: @rr-operation-status-lostnoreturn-color;
}

// ORDER Заявка
.rr-operation-status-color-ORDER {
    color: @rr-operation-status-order-color;
}

// DRAFT Черновик
.rr-operation-status-color-DRAFT {
    color: @rr-operation-status-draft-color;
}

// PROLONG Продление
.rr-operation-status-color-PROLONG {
    color: @rr-operation-status-prolong-color;
}

// RENT Выдача
.rr-operation-status-color-RENT {
    color: @rr-operation-status-rent-color;
}

// RETURN Возврат
.rr-operation-status-color-RETURN {
    color: @rr-operation-status-return-color;
}

// RETURNBROKEN Прием с дефектом
.rr-operation-status-color-RETURN_BROKEN {
    color: @rr-operation-status-returnbroken-color;
}

.rr-operation-status-color-AUTOMATIC_RULE {
    color: #a0a0a0;
}

.rr-operation-status-color-SUBRENT_DRAFT {
    color: #575962;
}

.rr-operation-status-color-SUBRENT_BOOK_SHIPMENT {
    color: @rr-operation-status-book-color;
}

.rr-operation-status-color-SUBRENT_ACCEPT_SHIPMENT {
    color: @rr-operation-status-rent-color;
}

.rr-operation-status-color-SUBRENT_RETURN_TO_SHIPPER {
    color: @rr-operation-status-return-color;
}

.rr-operation-status-color-SUBRENT_CANCEL {
    color: @rr-operation-status-cancel-color;
}

.rr-operation-status-color-SUBRENT_PROLONG {
    color: @rr-operation-status-prolong-color;
}

//////////////

// Правка
.rr-operation-status-bg-EDIT {
    background-color: #5c63a3 !important;
}

// BOOK Бронь
.rr-operation-status-bg-BOOK {
    background-color: @rr-operation-status-book-color !important;
}

// CANCEL Отмена
.rr-operation-status-bg-CANCEL {
    background-color: @rr-operation-status-cancel-color !important;
}

// CORRECT Корректировка
.rr-operation-status-bg-CORRECT {
    background-color: @rr-operation-status-correct-color !important;
}

// LOSTNORETURN Списание
.rr-operation-status-bg-LOST_NO_RETURN {
    background-color: @rr-operation-status-lostnoreturn-color !important;
}

// ORDER Заявка
.rr-operation-status-bg-ORDER {
    background-color: @rr-operation-status-order-color !important;
}

// PROLONG Продление
.rr-operation-status-bg-PROLONG {
    background-color: @rr-operation-status-prolong-color !important;
}

// RENT Выдача
.rr-operation-status-bg-RENT {
    background-color: @rr-operation-status-rent-color !important;
}

.rr-operation-status-bg-AUTOMATIC_RULE {
    background-color: #a0a0a0 !important;
}

// RETURN Возврат
.rr-operation-status-bg-RETURN {
    background-color: @rr-operation-status-return-color !important;
}

// RETURNBROKEN Прием с дефектом
.rr-operation-status-bg-RETURN_BROKEN {
    background-color: @rr-operation-status-returnbroken-color !important;
}

.rr-operation-status-bg-DRAFT {
    background-color: #fff !important;
    color: @rr-operation-status-draft-color;
    font-weight: 500;
}

//
.rr-operation-status-bg-SUBRENT_DRAFT {
    background-color: #fff !important;
    color: @rr-operation-status-draft-color;
    border-right: 1px solid #575962;
    font-weight: 500;
}

.rr-operation-status-bg-SUBRENT_DRAFT .ant-badge {
    color: @rr-operation-status-draft-color;
}

.rr-operation-status-bg-SUBRENT_DRAFT .ant-badge-count {
    color: @rr-operation-status-draft-color;
    box-shadow: 0 0 0 1px @rr-operation-status-draft-color inset !important;
}

.rr-operation-status-bg-SUBRENT_DRAFT .ant-badge-dot {
    background-color: @rr-operation-status-draft-color !important;
}

.rr-operation-status-bg-SUBRENT_BOOK_SHIPMENT {
    background-color: #fff !important;
    color: @rr-operation-status-book-color;
    border-right: 1px solid #575962;
    font-weight: 500;
}

.rr-operation-status-bg-SUBRENT_BOOK_SHIPMENT .ant-badge {
    color: @rr-operation-status-book-color;
}

.rr-operation-status-bg-SUBRENT_BOOK_SHIPMENT .ant-badge-count {
    color: @rr-operation-status-book-color;
    box-shadow: 0 0 0 1px @rr-operation-status-book-color inset !important;
}

.rr-operation-status-bg-SUBRENT_BOOK_SHIPMENT .ant-badge-dot {
    background-color: @rr-operation-status-book-color !important;
}

.rr-operation-status-bg-SUBRENT_ACCEPT_SHIPMENT {
    background-color: #fff !important;
    color: @rr-operation-status-rent-color;
    border-right: 1px solid #575962;
    font-weight: 500;
}

.rr-operation-status-bg-SUBRENT_ACCEPT_SHIPMENT .ant-badge {
    color: @rr-operation-status-rent-color;
}

.rr-operation-status-bg-SUBRENT_ACCEPT_SHIPMENT .ant-badge-count {
    color: @rr-operation-status-rent-color;
    box-shadow: 0 0 0 1px @rr-operation-status-rent-color inset !important;
}

.rr-operation-status-bg-SUBRENT_ACCEPT_SHIPMENT .ant-badge-dot {
    background-color: @rr-operation-status-rent-color !important;
}

.rr-operation-status-bg-SUBRENT_RETURN_TO_SHIPPER {
    background-color: #fff !important;
    color: @rr-operation-status-return-color;
    border-right: 1px solid #575962;
    font-weight: 500;
}

.rr-operation-status-bg-SUBRENT_RETURN_TO_SHIPPER .ant-badge {
    color: @rr-operation-status-return-color;
}

.rr-operation-status-bg-SUBRENT_RETURN_TO_SHIPPER .ant-badge-count {
    color: @rr-operation-status-return-color;
    box-shadow: 0 0 0 1px @rr-operation-status-return-color inset !important;
}

.rr-operation-status-bg-SUBRENT_RETURN_TO_SHIPPER .ant-badge-dot {
    background-color: @rr-operation-status-return-color !important;
}

.rr-operation-status-bg-SUBRENT_CANCEL {
    background-color: #fff !important;
    color: @rr-operation-status-cancel-color;
    border-right: 1px solid #575962;
    font-weight: 500;
}

.rr-operation-status-bg-SUBRENT_CANCEL .ant-badge {
    color: @rr-operation-status-cancel-color;
}

.rr-operation-status-bg-SUBRENT_CANCEL .ant-badge-count {
    color: @rr-operation-status-cancel-color;
    box-shadow: 0 0 0 1px @rr-operation-status-cancel-color inset !important;
}

.rr-operation-status-bg-SUBRENT_CANCEL .ant-badge-dot {
    background-color: @rr-operation-status-cancel-color !important;
}

.rr-operation-status-bg-SUBRENT_PROLONG {
    background-color: #fff !important;
    color: @rr-operation-status-prolong-color;
    border-right: 1px solid #575962;
    font-weight: 500;
}

.rr-operation-status-bg-SUBRENT_PROLONG .ant-badge {
    color: @rr-operation-status-prolong-color;
}

.rr-operation-status-bg-SUBRENT_PROLONG .ant-badge-count {
    color: @rr-operation-status-prolong-color;
    box-shadow: 0 0 0 1px @rr-operation-status-prolong-color inset !important;
}

.rr-operation-status-bg-SUBRENT_PROLONG .ant-badge-dot {
    background-color: @rr-operation-status-prolong-color !important;
}

//

.rr-menu-operation-draft-item {
    background-color: #fff !important;
    border-right: 1px solid #575962;
    border-left: 1px solid #575962;
}

.rr-menu-operation-draft-item .ant-badge {
    color: #575962;
}

.rr-menu-operation-draft-item .ant-badge-count {
    color: #575962;
    box-shadow: 0 0 0 1px #575962 inset !important;
}

.rr-menu-operation-draft-item .ant-badge-dot {
    background-color: #575962 !important;
}

.rr-entity-action-TO_ARCHIVE-color {
    color: @rr-entity-to-archive-color;
}

.rr-entity-action-FROM_ARCHIVE-color {
    color: @rr-entity-from-archive-color;
}

// TODO Убрать, когда все rr-dot будут заменены на компонент Dot
.rr-dot {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    display: inline-block;
}

.rr-calendar-popover {
    width: unset;

    .ant-popover-inner-content {
        padding: 0;
    }

    &.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
    &.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
    &.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
        border-top-color: #f4f5f8;
        border-left-color: #f4f5f8;
    }
}

body.rr-is-touch-device .ant-menu-inline-collapsed-tooltip {
    display: none;
}

.custom-scrollbar {
    & ::-webkit-scrollbar,
    &::-webkit-scrollbar {
        position: absolute;
        width: 12px;
        height: 12px;
    }
    & ::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track {
        position: absolute;
        background-color: #f0f2f5;
    }
    & ::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
        position: absolute;
        border-radius: 6px;
        background-color: #525a95;
        border: 3px solid #f0f2f5;
    }
}

.rr-status-filter-separator {
    cursor: default;
    margin-right: -32px;
    margin-left: -12px;
    height: 10px;
    margin-top: -5px;
    margin-bottom: -5px;
    padding: 0px;
    display: flex;
    align-items: center;
}

.rr-status-filter-separator:hover,
.rr-status-filter-separator:active,
.rr-status-filter-separator:focus {
    background-color: #fff;
}

//////////////////////////
//////////////////////////

// BOOK Бронь
.rr-operation-avatar-BOOK {
    background-color: @rr-operation-status-book-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-book-color, 15%);
}

// CANCEL Отмена
.rr-operation-avatar-CANCEL {
    background-color: @rr-operation-status-cancel-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-cancel-color, 15%);
}

// CORRECT Корректировка
.rr-operation-avatar-CORRECT {
    background-color: @rr-operation-status-correct-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-correct-color, 15%);
}

// LOSTNORETURN Списание
.rr-operation-avatar-LOST_NO_RETURN {
    background-color: @rr-operation-status-lostnoreturn-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-lostnoreturn-color, 15%);
}

// ORDER Заявка
.rr-operation-avatar-ORDER {
    background-color: @rr-operation-status-order-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-order-color, 15%);
}

// PROLONG Продление
.rr-operation-avatar-PROLONG {
    background-color: @rr-operation-status-prolong-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-prolong-color, 15%);
}

// RENT Выдача
.rr-operation-avatar-RENT {
    background-color: @rr-operation-status-rent-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-rent-color, 15%);
}

.rr-operation-avatar-AUTOMATIC_RULE {
    background-color: #a0a0a0 !important;
    box-shadow: 0px 0px 0px 12px fade(#a0a0a0, 15%);
}

// RETURN Возврат
.rr-operation-avatar-RETURN {
    background-color: @rr-operation-status-return-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-return-color, 15%);
}

// RETURNBROKEN Прием с дефектом
.rr-operation-avatar-RETURN_BROKEN {
    background-color: @rr-operation-status-returnbroken-color !important;
    box-shadow: 0px 0px 0px 12px fade(@rr-operation-status-returnbroken-color, 15%);
}

.rr-operation-avatar-DRAFT {
    background-color: #fff;
    box-shadow: 0px 0px 0px 12px @rr-operation-status-draft-color;
    color: @rr-operation-status-draft-color;
}

.rr-operation-avatar-SUBRENT_DRAFT {
    background-color: #fff;
    box-shadow: 0px 0px 0px 12px @rr-operation-status-draft-color;
    color: @rr-operation-status-draft-color;
}

.rr-operation-avatar-SUBRENT_BOOK_SHIPMENT {
    background-color: #fff;
    box-shadow: 0px 0px 0px 12px @rr-operation-status-book-color;
    color: @rr-operation-status-book-color;
}

.rr-operation-avatar-SUBRENT_ACCEPT_SHIPMENT {
    background-color: #fff;
    box-shadow: 0px 0px 0px 12px @rr-operation-status-rent-color;
    color: @rr-operation-status-rent-color;
}

.rr-operation-avatar-SUBRENT_RETURN_TO_SHIPPER {
    background-color: #fff;
    box-shadow: 0px 0px 0px 12px @rr-operation-status-return-color;
    color: @rr-operation-status-return-color;
}

.rr-operation-avatar-SUBRENT_CANCEL {
    background-color: #fff;
    box-shadow: 0px 0px 0px 12px @rr-operation-status-cancel-color;
    color: @rr-operation-status-cancel-color;
}

.rr-operation-avatar-SUBRENT_PROLONG {
    background-color: #fff;
    box-shadow: 0px 0px 0px 12px @rr-operation-status-prolong-color;
    color: @rr-operation-status-prolong-color;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.rr-new-app-version-block {
    background: linear-gradient(-34deg, #7831b5, #21d3fd);
    background: -moz-linear-gradient(-34deg, #7831b5, #21d3fd);
    background: -webkit-linear-gradient(-34deg, #7831b5, #21d3fd);
    background: -o-linear-gradient(-34deg, #7831b5, #21d3fd);
    background-size: 200% 200%;
    animation: gradient 4.5s ease infinite;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10000000;
    color: #ffffff;
    padding: 2px 24px;
    font-weight: 700;
    box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
    cursor: default;
    user-select: none;
    max-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}

.rr-new-app-version-block__link {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
}

.rr-new-app-version-block__link:hover {
    //text-decoration: underline;
    opacity: 0.9;
}

.rr-actions-popover .ant-popover-inner-content {
    padding: 0;
}

.rr-btn-confirm-actualize-dates,
.rr-btn-confirm-actualize-dates:hover,
.rr-btn-confirm-actualize-dates:focus {
    border: 1px solid #21bfa4;
    height: 38px;
    line-height: 36px;
    color: #21bfa4;
}

.rr-btn-confirm-actualize-dates .anticon {
    font-size: 20px;
    vertical-align: middle;
    margin-top: 0px;
}

.rr-btn-confirm-actualize-dates:hover {
    opacity: 0.75;
}

.ant-dropdown-menu {
    border-radius: 0;
}

.ant-modal-wrap {
    overflow-y: scroll;
}

.ant-modal-wrap::-webkit-scrollbar-track {
    background-color: #848395;
}

.ant-modal-wrap::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #525a95;
    border: 3px solid #848395;
}

.link-hover-underline {
    &:hover {
        text-decoration: underline;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';