.rr-pricingScheme-editor {
  margin: -28px -30px 0px;
}

.rr-pricingScheme-editor-title {
  font-size: 17px;
  font-weight: 600;
  padding: 10px 30px;
  background-color: #F8F9FA;
  display: flex;
}

.rr-pricingScheme-editor-title-title {
  flex-grow: 1;
}

.rr-pricingScheme-editor-steps {
  border-bottom: 6px solid #F8F9FA;
}

.rr-pricingScheme-editor-steps-info {
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
}


.rr-pricingScheme-editor-step {
  display: flex;
  height: 56px;
  align-items: center;
}


.rr-pricingScheme-editor-step > div:nth-child(1) {
  margin-left: 42px;
  width: 82px;
}

.rr-pricingScheme-editor-step > div:nth-child(2) {
  width: 110px;
  text-align: center;
}

.rr-pricingScheme-editor-step > div:nth-child(3) {
  width: 70px;
}

.rr-pricingScheme-editor-step > div:nth-child(4) {
  font-size: 20px;
  margin-top: 1px;
  color: #CCCCCC;
  text-align: center;
}

.rr-pricingScheme-editor-step > div:nth-child(5) {
  margin-left: 30px;
}

.rr-pricingScheme-editor-step > div:nth-child(6) {
  flex-grow: 1;
  margin-right: 75px;
  width: 130px;
  text-align: center;
}

.rr-pricingScheme-editor-step > div:nth-child(7) {
  margin-right: 40px;
}


.rr-pricingScheme-editor-step:nth-child(2n) {
  background-color: #F8F9FB;
}

.has-error .rr-pricingScheme-editor .ant-input-number, .has-error .ant-time-picker-input {
  border-color: unset;
}

.has-error .rr-pricingScheme-editor .ant-input-number-input {
  border-color: #dcdfe8;
}

.has-error .rr-pricingScheme-editor .ant-input-number-input:focus {
  border-color: unset;
}

.has-error .rr-pricingScheme-editor .ant-input-number-input:hover {
  border-color: #c9cedc;
}

.has-error .rr-pricingScheme-editor .ant-input-number-focused {
  box-shadow: none;
}


.has-error .rr-pricingScheme-editor .rr-input-number .ant-input-number-input {
  background-color: unset;
}

.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-up-inner,
.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-down-inner {
  color: #575962;
}

.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #CCC;
}

.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-input-wrap {
  z-index: 2;
}

.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-input-wrap {
  background-color: unset;
}

.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-down-disabled .ant-input-number-handler-down-inner,
.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-up-disabled .ant-input-number-handler-up-inner,
.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-down-disabled:active .ant-input-number-handler-down-inner,
.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-up-disabled:active .ant-input-number-handler-up-inner,
.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
.rr-pricingScheme-editor .rr-input-number.ant-input-number .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
  color: rgba(0, 0, 0, .1);
}

.rr-pricingScheme-editor .rr-input-number .ant-input-number-input {
  font-weight: 600;
  color: #343f9a;
}

.rr-pricingScheme-editor .rr-input-number.rr-input-number-error .ant-input-number-input {
  color: #f4516c;
}

.rr-pricingScheme-editor .ant-input-number.rr-input-number-error .ant-input-number-input {
  color: #f4516c;
  border-color: rgba(244, 82, 109, 0.4);
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';