.rr-PricingScheme-price__popover-content {
  padding: 0;
  font-size: 12px;
  color: rgba(87, 89, 98, 0.8);
  font-weight: 500;
  max-width: 235px;
}

.rr-PricingScheme-price__popover-content > div:nth-child(1) {
  padding: 8px 12px;
  border-bottom: 1px solid #cccccc;
}

.rr-PricingScheme-price__popover-content > div:nth-child(2) {
  padding: 10px 16px;
}

.rr-PricingScheme-price__popover-content > div:nth-child(3) {
  padding: 10px 16px;
  border-top: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rr-PricingScheme-price__popover-content > div:nth-child(3) > * {
  cursor: pointer;
  color: #525993;
  text-decoration: underline;
  font-size: 11px;
  font-weight: 400;
}

.rr-PricingScheme-price__popover-content > div:nth-child(2) > div {
  display: flex;
  padding-bottom: 4px;
}

.rr-PricingScheme-price__popover-content > div:nth-child(2) > div > div:first-child {
  flex-grow: 1;
  margin-right: 40px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';