.calendar-timeline.compact .summaryScheduleCalendar-sidebar-group {
  font-size: 11px;
}

.summaryScheduleCalendar-sidebar-group-icon {
  min-width: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  user-select: none;
}

.summaryScheduleCalendar-sidebar-group-icon-disabled {
  opacity: 0.15;
}

.summaryScheduleCalendar-sidebar-group-title {
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 220px;
}

.calendar-timeline.compact .summaryScheduleCalendar-sidebar-group {
  line-height: 1;
}

.summaryScheduleCalendar-sidebar-group {
  display: flex;
  width: 100%;
  max-width: 100%;
  line-height: 1.1;

  a {
    color: #343f9a;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      color: #343f9a;
      text-decoration: underline;
    }
  }
}

.summaryScheduleCalendar-sidebar-group-collapse-all {
    color: #343f9a;
    text-decoration: underline;
    font-weight: 600;
}

.summaryScheduleCalendar-sidebar-group-nomenclature {
  overflow: hidden;
  text-overflow: ellipsis;
}

.summaryScheduleCalendar-sidebar-group-nomenclature .anticon {
  font-size: 20px;
  margin-right: 4px;
  vertical-align: sub;
}

.calendar-timeline.compact .summaryScheduleCalendar-sidebar-group-nomenclature .anticon {
  font-size: 16px;
}

.summaryScheduleCalendar-sidebar-group-variant {
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    font-weight: 400;
  }
}

.summaryScheduleCalendar-sidebar-group-instance {
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;

  a, a:hover {
    font-weight: 400;
    color: unset;
  }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';