.rr-operationForm-kit-add-element-popover {
    min-width: 400px;
}

.rr-operationForm-kit-add-element-popover .ant-select-selection,
.rr-operationForm-kit-add-element-popover .ant-select-selection:hover,
.rr-operationForm-kit-add-element-popover .ant-select-selection:focus,
.rr-operationForm-kit-add-element-popover .ant-select-selection:active,
.rr-operationForm-kit-add-element-popover .ant-select-focused .ant-select-selection {
    border-color: transparent;
}

.rr-operationForm-kit-add-element-popover-select-dropdown .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
    color: #ababab;
}

.rr-operationForm-kit-add-element-popover-select-dropdown .ant-select-dropdown-menu-item {
    padding: 0px 12px;
    display: flex;
    align-items: center;
}

.rr-operationForm-kit-add-element-popover-select-dropdown .rr-operationForm-kit-add-element-popover-select-dropdown__kit-icon {
    font-size: 21px;
    margin-right: 8px;
}

.rr-operationForm-kit-add-element-popover-select-dropdown .rr-operationForm-kit-add-element-popover-select-dropdown__current-kit-icon {
    font-size: 18px;
    color: #ababab;
    margin-right: 3px;
}

.rr-operationForm-kit-add-element-popover-select-dropdown .rr-operationForm-kit-add-element-popover-select-dropdown__item-nested {
    padding-left: 42px;
}

.rr-operationForm-kit-add-element-popover .ant-select-selection--multiple .ant-select-selection__clear {
    top: 50%;
}

.rr-operationForm-kit-add-element-popover-select-dropdown.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 12px;
}

.rr-operationForm-kit-add-element-popover-select-dropdown .rr-operationForm-kit-add-element-popover-select-dropdown__label {
    overflow: hidden;
    text-overflow: ellipsis;
}

.rr-operationForm-kit-add-element-popover .ant-select-selection__choice {
    max-width: 282px;
}

.rr-grid-actions-popover-header-xxx {
    padding: 8px;
    min-height: unset;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';