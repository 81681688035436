.select-project-popover-header {
    padding: 8px;
    min-height: unset;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.select-project-popover-overlay {
    min-width: 400px;

    .ant-select-selection {
        &:hover,
        &:focus,
        &:active {
            border-color: transparent;
        }
    }
    .ant-select-focused {
        .ant-select-selection {
            border-color: transparent;
        }
    }
}

.select-project-popover-disabled {
    display: flex;
    align-items: center;
    color: #ccc;
    cursor: not-allowed;
    margin: -5px -12px;
    padding: 5px 12px;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';